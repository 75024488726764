import styled, { css } from 'styled-components';
import Theme from '../theme/index';

const { colors: {red} } = Theme;
export const TopNav = styled.nav`
    transition: background-color .3s ease;
    width: 100%;
    z-index: 1000;
    height: 65px;
    background-color: ${props => props.scroll ? '#233034' : 'rgb(0,0,0,.16)'};
    box-shadow: 0 1px 3px 0 rgba(0,0,0,.14);
    top: 0;
    position: fixed;
    display: flex;

    @media (min-width: 768px) {
        background-color: ${props => props.scroll ? '#233034' : 'rgb(0,0,0,.16)'};
    }
`

export const Fonter = styled.div`
    font-family: 'Jarkarta', sans-serif;
`

export const Flexer = styled.div`
    display: flex;
    flex: 1 1 auto;
    justify-content: center;
`

export const HeaderGrid = styled.div`
    display: ${props => props.scroll ? 'flex' : 'grid'};
    justify-content: space-between;
    align-items: center;

    margin-left: 16px;
    margin-right: 16px;

    grid-column-gap: 16px;
    grid-template-columns: repeat(2,minmax(0,1fr));
    grid-template-rows: auto;
    max-width: 1600px;
    width: 100%;

    /* @media (min-width: 1440px) {
        margin-left: 40px;
        margin-right: 40px;
    };

    @media (min-width: 1200px) {
        grid-column-gap: 24px;
    }

    @media (min-width: 960px) {
        margin-left: 32px;
        margin-right: 32px;
        grid-template-columns: repeat(12,minmax(0,1fr));
    };

    @media (min-width: 768px) {
        grid-template-columns: repeat(8,minmax(0,1fr));
    } */

`

export const HeaderFlex = styled.div`
    height: 100%;
    width: 100%;
    grid-column: span 2;
    justify-content: center;
    align-items: center;
    display: flex;

    
    @media (min-width: 1440px) {
        grid-column: span 12;
    }
    @media (min-width: 1200px) {
        grid-column: span 12;
    }
    @media (min-width: 960px) {
        grid-column: span 12;
    }

    @media (min-width: 768px) {
        grid-column: span 8;
    } 

    @media (min-width: 360px) {
        grid-column: span 2;
    } 
`

export const LogoAnchor = styled.a`
    display: ${props => props.scroll ? 'none' : 'auto'};

    @media (min-width:768px) {
        display: inline-block;
    }
`

export const LogoImg = styled.img`
    width: 105px;
`

export const HamWrapper = styled.div`
    border-radius: 50%;
    display: none;
    /* margin: 0 4px; */
    padding: 12px;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    /* height: 24px;
    width: 24px; */
    flex: 0 0 auto;

    &:hover {
        background-color: rgba(60,64,67,0.08);
        outline: none
    }

    @media (max-width: 768px) {
        display: ${props => props.scroll ? 'none' : 'block'};
    }
`

export const MenuFlex = styled.div`
    display: ${props => props.scroll ? 'none' : 'flex'};
    justify-content: flex-end;

    /* @media (min-width:768px) {
        display: flex;
    } */


 
`

export const HeaderLine = styled.div`
    margin-left: 32px;
    margin-right: 32px;
    color: #fdfdfd;
    display: none;  
    font-size: 13px;
    letter-spacing: 1px;
    line-height: 1.2;
    text-align: center;

    @media (min-width: 960px) {
        font-size: 16px;
        line-height: 1.2;
    }

    @media (min-width: 768px) {
        align-items: center;
        display: ${props => props.scroll ? 'flex' : 'none'};
    }
`
export const MenuFlexItem = styled.div`
    cursor: pointer;
    font-size: 13px;
    display: none;
    color: white;
    line-height: 16.1px;
    font-weight: 700;
    font-family: 'Jarkarta', sans-serif;
    align-items: center;
    white-space: nowrap;
    
    &>svg {
            margin-right: 10px;
        }
      &:not(:last-child) {
        margin-right: 40px;
    }
    @media (min-width: 768px) {
        display: flex;
    }
`


export const LogoWrapper = styled.a`
    width: 90px;
    height: auto;
    margin-top: 0px;
    display: flex;

    &:hover {
        cursor: pointer; 
    }
`

export const CTAButton = styled.button`
    display: ${props => props.scroll ? 'flex' : 'none'};
    border-radius: 0;
    height: 100%;
    margin-left: -16px;
    margin-right: -16px;
    width: calc(100% + 32px);
    font-size: 16px;
    align-items: center;
    transition: background-color 0.3s ease;
    height: 65px;
    justify-content: center;
    margin: 0px -16px;
    overflow-x: visible;
    overflow-y: visible;
    padding: 0px 24px;
    position: relative;
    /* width: 390.236px; */
    background-color: #e32900;
    color: white;
    font-family: 'Jarkarta', sans-serif;


    @media (min-width: 768px) {
        border-radius: 4px;
        height: 48px;
        margin: 0;
        width: auto;
        align-items: center;
        font-weight: 500;
    }

    &:hover {
        background-color: #d61c00;
    }
`

export const PermanentCTAButton = styled.button`
    vertical-align: middle;
    align-items: center;
    justify-content: center;
    position: relative;
    border: 1px solid transparent;
    border-radius: 4px;
    cursor: pointer;
    display: inline-flex;
    outline: none;
    transition: all .1s ease-out;
    box-shadow: none;
    background-color: ${props => props.color == 'black' ? '#1a1a1a' : props.color == 'white' ? '#fff' : '#e32900'};
    color: ${props => props.color == 'white' ? '#1a1a1a' : 'white'};
    font-size: 18px;
    padding: 4px 30px;
    height: auto;
    line-height: 1.5;
    margin: 12px 0;
    min-height: 54px;
    transition: background-color 0.3s ease;


    &:hover {
        background-color: ${props => props.color == 'white' ? '#fafafa' : props.color == 'red' ? '#d61c00' : '#1a1a1a' };
    }

    @media (min-width: 768px) {
        margin: 0 16px
    }
`

export const AboveFoldSection = styled.section`
    background-color: #fdfdfd;
`

export const Height = styled.div`
    height: 516px;
    position: relative;

    @media (min-width: 360px) {
        height: 450px;
    }
    
    @media (min-width: 484px) {
        height: 470px;
    }

    @media (min-width: 768px) {
        height: 514px;
    }
`

export const TopPart = styled.div`

    border-bottom: 481px solid transparent;
    border-bottom-left-radius: 50%;
    border-bottom-right-radius: 50%;
    box-sizing: content-box;
    height: 100%;
    overflow: hidden;
    position: relative;
    width: 100%;
    border-bottom-width: 500px;
 

    @media (min-width: 360px) {
        border-bottom-width: 410px;
    }

    @media (min-width: 484px) {
        border-bottom-width: 410px;
    }

    @media (min-width: 768px) {
        border-bottom-width: 441px;
    }

`

export const AboveFoldArrow = styled.div`
    line-height: 1.15;   
    background-color: #fdfdfd;
    border-radius: 50%;
    bottom: -20px;
    height: 40px;
    transform: translateX(-50%);
    width: 40px;
    left: 50%;
    position: absolute;
`

export const ContentFlexer = styled.div`
    line-height: 1.15;
    display: flex;
    justify-content: center;
    flex: 1 1 auto;
    min-height: 0;
    min-width: 0;

    &::before {
        position: absolute;
        width: 100%;
        background-image: linear-gradient(180deg,transparent,rgba(0,0,0,.52));
        content: "";
        height: 100%;
        opacity: .88;
    };
`

export const ColumnFlex = styled.div`
    line-height: 1.15;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    padding-bottom: 30px;
`
export const SmallHeading = styled.div`
    box-sizing: border-box;
    color: #fff;
    font-size: 18px;
    letter-spacing: 1px;
    line-height: 1.33;
    text-align: center;
    text-shadow: 0 0 4px rgba(0,0,0,.5);
    text-transform: uppercase;
    margin-bottom: 24px;
    padding-top: 6rem;

    @media (min-width: 960px) {
        margin-bottom: 10px;
        padding-top: 9rem;
    }
`

export const GridFiller = styled.div`
    width: 100%;
    grid-column: span 2;


    @media (min-width: 360px) {
        grid-column: span 2;
    }
    @media (min-width: 768px) {
        grid-column: span 8;
    }
    @media (min-width: 960px) {
        grid-column: span 12;
    }

`
export const MainHeading = styled.div`
    line-height: 1.15;
    color: #fff;
    font-weight: 700;
    margin-bottom: 2rem;
    text-align: center;
    text-shadow: 0 0 24px rgba(0,0,0,.5);
    font-size: 24px;
    letter-spacing: 1px;

    @media (min-width: 768px) {
        font-size: 40px;
    }
`

export const HeaderGridFiller = styled.div`
    height: 100%;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    display: flex;
    grid-column: span 2;

    @media (min-width: 1600px) {
        grid-column: span 12;
    }

    @media (min-width: 1440px) {
        grid-column: span 12;
    }

    @media (min-width: 1200px) {
        grid-column: span 12;
    }

    @media (min-width: 960px) {
        grid-column: span 12;
    }

    @media (min-width: 768px) {
        grid-column: span 8;
    }

    @media (min-width: 360px) {
        grid-column: span 2;
    }


`
export const RelativeBox = styled.div`
    
    grid-column-gap: 16px;
    display: grid;
    grid-template-columns: repeat(2,minmax(0,1fr));
    grid-template-rows: auto;
    max-width: 1600px;
    width: 100%;
    height: 100%;
    position: relative;
    margin-left: 16px;
    margin-right: 16px;

    
    @media (min-width: 768px) {
        grid-template-columns: repeat(8,minmax(0,1fr));
    }
    @media (min-width: 960px) {
        grid-template-columns: repeat(12,minmax(0,1fr));
    }
    
    @media (min-width: 960px){
        margin-left: 32px;
        margin-right: 32px;
    }


`

export const ButtonHolder = styled.div`
    line-height: 1.15;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: -12px 0px;

    @media (min-width: 768px) {
        flex-direction: row;
        margin: 0 -16px;
    }

`

export const SmallTextHolder = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 2rem;
`

export const SmallText = styled.div`
    color: #fff;
    font-size: 14px;
    letter-spacing: .5px;
    line-height: 1.71;
    margin: 1rem 8px 0;
    text-align: center;
    text-shadow: 0 0 8px rgba(0,0,0,.5);
    margin-top: 0;
    order: 0;
    width: auto;
`

export const ButtonTextHolder = styled.div`
    font-weight: 700;
    cursor: pointer;
    color: #fff;
    font-size: 18px;
    line-height: 1.5;
    box-sizing: border-box;
`

export const PointerandProof = styled.section`
  
    background-color: #fdfdfd;
    padding-bottom: 4rem;
`

export const ThreePointers = styled.div`
    display: flex;
    justify-content: center;
    flex: 1 1 auto;
    min-height: 0;
    min-width: 0;
`

export const PointerHolder = styled.div`
    width: 100%;
    padding-top: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;


    @media (min-width: 768px) {
        flex-direction: row;
        justify-content: space-between;
    }
`

export const Pointer = styled.div`
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 2rem;

    @media (min-width: 768px) {
            width: 33.33333%;

            &:not(:last-child) {

                padding-right: 10px;
            }
    }


`

export const Step = styled.div`
    color: ${red['100']};
    font-size: 14px;
    letter-spacing: 1px;
    line-height: 1.43;
    margin-top: 8px;
    text-transform: uppercase;
`

export const StepTitle = styled.div`
    color: #1a1a1a;
    font-size: 18px;
    font-weight: 700;
    margin: 8px 0;
`

export const StepDesc = styled.div`
    text-align: center;
    box-sizing: border-box;
    color: #383838;
    font-size: 14px;
    letter-spacing: .5px;
    line-height: 1.71;
`

export const CTAandProof = styled.div`
    overflow: hidden;
    position: relative;
    /* padding-bottom: 4rem; */
`

export const CTAHolder = styled.div`
    margin-top: 38px;
    /* margin-bottom: 64px; */
    display: flex;
    justify-content: center;
`

export const ProofHolder = styled.div`
    display: flex;
    justify-content: center;
    overflow: hidden;
    height: 21px;

    @media (min-width: 768px) {
        height: 48px;
    }
`

export const ProofImg = styled.img`
    max-width: 100%;
    border-style: none;
    font-family: "object-fit:contain";
    object-fit: contain;
    opacity: .37;
    height: 21px;
    margin: 0 .5rem;


    @media (min-width: 768px) {
        height: 48px;
        margin: 0 1rem;
    }
`

export const Wrapper = styled.div`
    display: grid;
    grid-template-rows: auto;
    max-width: 1600px;
    width: 100%;
    /* grid-template-columns: repeat(12,minmax(0,1fr)); */
    grid-column-gap: 24px;
    margin-left: 16px;
    margin-right: 16px;
    height: 100%;
    position: relative;

    @media (min-width: 960px) {
        margin-left: 32px;
        margin-right: 32px;
    }

    
    @media (min-width: 1440px) {
        margin-left: 40px;
        margin-right: 40px;
    }
    
    @media (min-width: 1600px) {
        margin-left: 64px; 
        margin-right: 64px;
    }

`
export const WrapperFlexer = styled.div`
    display: flex;
    justify-content: center;
    flex: 1 1 auto;
    min-height: 0;
    min-width: 0;
`

export const DarkSection = styled.section`
    overflow: hidden;
    padding: 2rem 0;
    background-color: #233034;

    @media (min-width: 768px) {
    padding: 4rem 0;
    }
`

export const GridContentHolder = styled.div`
    width: 100%;
    grid-column: span 2;

    
    @media (min-width: 1440px) {
        grid-column: span 12;
    }

    @media (min-width: 1200px) {
        grid-column: span 12;
    }

    @media (min-width: 960px) {
        grid-column: span 12;
    }

    @media (min-width: 768px) {
        grid-column: span 8;
    }

    @media (min-width: 360px) {
        grid-column: span 2;
    }
`

export const BottomFlexer = styled.div`
display: flex;
flex-direction: column;
@media (min-width: 1200px) {

    align-items: center;
    flex-direction: row;
    justify-content: space-between;
}


`
export const Benefits = styled.div`
    font-size: 14px;
    letter-spacing: 2px;
    line-height: 1.71;
    margin-bottom: 16px;
    text-align: center;
    text-transform: uppercase;
    color: #f7f7f7;
`

export const DarkTitle = styled.div`
    font-size: 24px;
    font-weight: 700;
    line-height: 1.33;
    text-align: center;
    margin-bottom: 44px;
    color: #fff;
`

export const DarkTitleBottom = styled.div`
    font-size: 24px;
    font-weight: 700;
    line-height: 1.33;
    text-align: center;
    margin-bottom: 44px;
    color: #fff;

    @media (min-width: 768px) {
        margin-bottom: 44px;
    }
    

    @media (min-width: 1200px) {
        margin-bottom: 0;
        text-align: left;
    }
`

export const DarkSubtitleBottom = styled.div`
    color: #f7f7f7;
    font-size: 14px;
    letter-spacing: 2px;
    line-height: 1.71;
    margin-bottom: 16px;
    text-align: center;
    text-transform: uppercase;
    @media (min-width: 1200px) {
        text-align: left;
    }
`


export const BenefitsFlex = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    flex-direction: column;

    @media (min-width: 768px) {
        flex-direction: row;
    }
`

export const Benefit = styled.div`

    text-align: left;
    margin-bottom: 3rem;
    text-align: center;

    @media (min-width: 768px) {
        margin-bottom: 24px;
        text-align: left;
        width: calc(50% - 12px);
    }

    @media (min-width: 768px) {
        &:not(:nth-child(2n)) {
            margin-right: 24px;
        }
    }

    

 @media (min-width: 960px) {
        &:not(:nth-child(2n)) {
            {
                margin-bottom: 0;
                margin-right: 0!important;
                width: calc(25% - 18px);
            }
        }
    }

@media (min-width: 960px) {
    margin-bottom: 0;
    margin-right: 0!important;
    width: calc(25% - 18px);
}

@media (min-width: 960px) {
        &:not(:nth-child(4n)) {
            margin-right: 24px!important;
        }
    }

`

export const IconTitle = styled.div`
    text-align: left;
    box-sizing: border-box;
    align-items: center;
    color: #c6c6c6;
    display: flex;

    font-size: 32px;
    justify-content: center;

    @media (min-width: 768px) {
    font-size: 35px;
    justify-content: flex-start;
}
`

export const BenefitTitle = styled.div`
  
    margin-top: 16px;
    color: #c6c6c6;
    font-size: 14px;
    letter-spacing: 2px;
    text-transform: uppercase;
`

export const BenefitSpacer = styled.div`

    background-color: #979797;
    height: 2px;
    width: 50px;
    margin: 24px auto;

    @media (min-width: 768px) {
        margin: 24px 0;
    }
`

export const BenefitsPara = styled.div`
    margin-bottom: 16px;
    color: #fff;
    font-size: 15px;
    line-height: 1.5;
`

export const DarkCTAHolder = styled.div`
    display: flex;
    justify-content: center;

    margin: 0 2rem;

    @media (min-width: 768px) {
    margin: 0;
    }
`

export const BenefitsCTAHolder = styled.div`
    display: flex;
    justify-content: center;

    @media (min-width: 768px) {
        margin-top: 5rem;
    }
`

export const ListingSample = styled.section`
    overflow: hidden;
    padding: 4rem 0;
    background-color: ${props => props.beige ? "#f7f7f7" : "#fff"};
`

export const SampleHeader = styled.div`
    color: #383838;
    font-size: 14px;
    letter-spacing: 2px;
    line-height: 1.71;
    margin-bottom: 16px;
    text-align: center;
    text-transform: uppercase;
`

export const SampleBold = styled.div`
    color: #1a1a1a;
    font-size: 24px;
    font-weight: 700;
    line-height: 1.33;
    text-align: center;
    margin-bottom: 44px;
`

export const FancyHolder = styled.div`
    display: flex;
    flex-wrap: wrap;
`

export const FancyImg = styled.img`
    max-width: 100%;
    border-style: none;
    font-family: "object-fit:cover";
    height: 100%;
    position: absolute;
    object-fit: cover;
    width: 100%;
    z-index: 0;

     ${props => props.special && css`
    @media (min-width: 1200px) {
       border-bottom-right-radius: 50%;
            border-top-right-radius: 50%;
            overflow: hidden;
            position: static;
     }
  `}
`

export const FancyChild = styled.div`
    cursor: pointer;
    background-color: #fff;
    border: 1px solid rgba(0,0,0,.05);
    border-radius: 2px;
    box-shadow: 0 5px 15px 0 rgb(0 0 0 / 10%);
    margin-bottom: 1rem;
    position: relative;
    width: 100%;
    display: flex;
    color: #1a1a1a;

    &::after {
            background: #0000007a;
            content: "";
            height: 100%;
            position: absolute;
            width: 100%;
        }

     &:nth-child(2), &:nth-child(3) {
        color: white;
        &::after {
            background: #0000007a;
            content: "";
            height: 100%;
            position: absolute;
            width: 100%;
        }
    }
    
    @media (min-width: 768px) {
        width: calc(50% - 12px);
    }
    
    @media (min-width: 768px) {
        
        &:nth-child(odd) {
            margin-right: 24px;
        }
    }
    @media (min-width: 1200px) {
        &:first-child, :nth-child(4) {
            width: calc(66.66667% - 16px);
             &::after {
            display: none;}
        }
    }
    
    @media (min-width: 1200px)     {
        height: 282px;
    }

    @media (min-width: 1200px) {
        &:nth-child(2), &:nth-child(3) {
            width: calc(33.33333% - 8px);
        }
    }

     

`



export const FancyBGImage = styled.img`
    
    max-width: 100%;
    border-style: none;
    font-family: "object-fit:cover";
    height: 100%;
    object-fit: cover;
    position: absolute;
    width: 100%;
    z-index: 0;
`

export const FancyContentHolder = styled.div`
    padding: 40px 16px;
    width: 100%;
    z-index: 1;
    color: #fff;
    @media (min-width: 1200px) {
        &:first-child, &:nth-child(4) {
        max-width: 50%;
        min-width: 330px;
    }

     ${props => props.special && css`
    @media (min-width: 1200px) {
        color: #1a1a1a;
        text-shadow: none;
        
     }
  `}
}
`

export const StartFlowButton = styled.a`
    background-color: transparent;
    text-decoration: none;
    cursor: pointer;
    /* display: inline-block; */
    font-size: 24px;
    font-weight: 700;
    line-height: 1;
    margin-bottom: 24px;
    color: inherit;
    
    text-shadow: inherit;
    display: flex;
    align-items: center;
    grid-column-gap: 4px;
    

    

`

export const ListingDetail = styled.div`
    display: flex;
    align-items: center;

    &:not(:last-child) {
    padding-bottom: 8px;
}
`

export const ListingDetailBold = styled.div`
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 1px;
    margin-right: 10px;
    min-width: 80px;
    text-transform: uppercase;
    width: 80px;
    /* color: #e4e4e4;

    @media (min-width: 1200px) {
    color: #626262;
} */
`
export const ListingDetailValue = styled.div`
    /* color: #fff; */
    font-size: 14px;
    line-height: 1.71;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-decoration: ${props => props.underLine ? "underline" : "none"}; 

    /* @media (min-width: 1200px) {
        color: #1a1a1a;
    } */
`

export const DrawerMenuItems = styled.div`
    display: flex;
    align-items: center;
    position: relative;
    color: #1a1a1a;
    cursor: pointer;
    font-size: 18px;
    margin: 24px 19px;

    &>svg {
        margin-right: 10px;
    }
`

export const ReviewSection = styled.section`

`

export const StickyReviews = styled.div`
  text-decoration: none;
  display: flex;
  align-items: center;
  background-color: #fff;
  /* cursor: pointer; */
  z-index: 250;
  padding: 20px;

  @media (min-width: 768px) {
    border: 1px solid rgba(0, 0, 0, 0.04);
    border-radius: 4px;
    bottom: 0;
    box-shadow: 0 0 16px 0 rgb(0 0 0 / 16%);
    ${(props) => (props.right ? "right" : "left")}: 50px;
    padding: 12px 16px;
    position: fixed;
  }
`;

export const NonStickyReviews = styled.div`
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  /* cursor: pointer; */
  z-index: 250;
  padding: 20px 20px 0px;
`;

export const ReviewLogo = styled.div`
    display: flex;
    border-right: 1px solid rgba(0,0,0,.15);
    padding-right: 16px;
`

export const ReviewRightSide = styled.div`
   
    padding-left: 8px;
    display: flex;
    flex-direction: column;
`

export const RightSideTop = styled.span`
    color: #626262;
    font-size: 12px;
    text-transform: capitalize;
`

export const StarsHolder = styled.div`
    display: flex;
    /* padding-top: 4px; */

`

export const StarSpan = styled.span`
    padding-right: 8px;
`

export const StarFlexer = styled.div`
    position: relative;
    box-sizing: border-box;
    display: inline-block;
`

export const StarContainer = styled.div`
   
    position: relative;
    display: inline-block;
    vertical-align: middle;
    padding-right: 0px;
`

export const RatingHolder = styled.div`
    display: flex;
    align-items: center;
    color: #626262;
    font-size: 12px;
    font-weight: 500;
    line-height: 2.33;
`

export const MiniLogo = styled.img`
    max-height: 100%;
    height: 24px;
    width: 24px;
    object-fit: contain;
`

export const ReviewHolder = styled.div`

`

export const ReviewFlex = styled.div`
    width: 100%;
    display: inline-block;
    border: none;
    border-radius: 2px;
    box-shadow: 0 5px 15px 0 rgb(0 0 0 / 10%);
    display: flex!important;
    flex-direction: column;
    overflow: hidden;
    position: relative;
    transition: height .3s ease;
    
    @media (min-width: 768px) {
        flex-direction: row;
        height: 300px;
    }
`

export const ReviewTextHolder = styled.div`
        padding: 24px;
    width: 100%;
`

export const ReviewHeading = styled.div`

    font-size: 18px;
    font-weight: 700;
    margin: 16px 0px;
`

export const ReviewBody = styled.div`

    line-height: 27px;
    max-height: 81px;
    overflow: hidden;
    position: relative;
    width: 90%;
`

export const ReviewReadMore = styled.div`
    background: #fff;
    right: 0;
    
    bottom: 0;
    position: absolute;

    &::after {
        background-image: linear-gradient(90deg,hsla(0,0%,100%,0),#fff 90%);
        content: "";
        height: 100%;
        left: -24px;
        pointer-events: none;
        width: 24px;
        z-index: 1;
        bottom: 0;
        position: absolute;
}

`

export const ReadMoreLink = styled.a`
    background-color: transparent;
    text-decoration: none;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #1a1a1a;
    font-size: 14px;
    line-height: 1.71;
    font-weight: 700;
    position: relative;

    &::after {
        background-color: #087bcc;
        /* bottom: 3px; */
        bottom: 0;
        content: "";
        height: 2px;
        left: 0;
        position: absolute;
        width: 100%;
    }
`

export const ReviewGrid = styled.div`
  display: grid;
  grid-row-gap: 20px;

  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 34px;

    & * {
      ${(props) =>
        props.font === "pricing" &&
        `
        font-family: "Inter", sans-serif !important;
        `}
    }
  }
`;